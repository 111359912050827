
.demos {
    margin: 3em auto;
    padding: 2em 0;
}

.demos a {
    background: #3498db;
    border-radius: 20px / 50px;
    color: #fff;
    font-weight: 800;
    letter-spacing: 1px;
    padding: 1.25rem 1.1rem;
    text-transform: uppercase;
    width: 15em;
}

.demos a:hover {
    background: #2980b9;
}